import React, { useEffect, useMemo, useState } from 'react'
import { capitalize, create, get, isEmpty } from 'lodash-es'
import { Form, Message } from '@waylay/react-components'
import { useNavigate } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import NewModal from '../../Common/NewModal'
import { verifyPlugV0Exists, verifyPlugV2Exists } from './usePlug'
import { useFlag } from '~/lib/flags'
import {
  getValidationSchema,
  useCreateFromExample,
} from '../Common/useCustomFunctions'
import styled from '@emotion/styled'
import { RuntimeContainer } from '../Common/useRuntimes'
import RuntimeSelection from '../Common/Tabs/Code/RuntimeSelection'
import { CustomFunctionsType, PlugType } from '../Common/Types'
import { useToasts } from 'react-toast-notifications'
import useSWRMutation from 'swr/mutation'

interface INewModalProps {
  children: any
  type?: PlugType
}

const Decorator = styled.section`
  margin-top: 10px;
`

const initialValidationSchema = Yup.object().shape({
  name: Yup.string().required().max(100),
  type: Yup.string().oneOf(Object.values(PlugType)).required(),
})

const ModalView = ({ children, type = PlugType.Sensor }: INewModalProps) => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const edgeMode = useFlag('edgeMode', false)
  const { majorRuntimes, nonCallbackRuntimesMap } =
    RuntimeContainer.useContainer()
  const [validationSchema, setValidationSchema] = useState(
    initialValidationSchema,
  )
  majorRuntimes && delete majorRuntimes.Native

  const initialRuntime = useMemo(() => {
    if (!isEmpty(nonCallbackRuntimesMap))
      return Object.entries(nonCallbackRuntimesMap)[0]
    return []
  }, [nonCallbackRuntimesMap])

  const { trigger: createFromExample, isMutating: createLoading } =
    useCreateFromExample()

  const createPlugin = useFormik({
    initialValues: {
      name: '',
      type,
      runtime: {
        name: initialRuntime?.[1]?.name,
        label: initialRuntime?.[0],
      },
    },
    onSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema,
  })

  useEffect(() => {
    if (!edgeMode) {
      const getSchema = () => {
        getValidationSchema('node-legacy', '0.2.0', 'manifest')
          .then(res => {
            const nameValidations = res.properties.name
            const newValidationSchema = Yup.object().shape({
              name: Yup.string()
                .required()
                .max(nameValidations.maxLength)
                .matches(nameValidations.pattern, nameValidations.errorMessage),
              type: Yup.string().oneOf(Object.values(PlugType)).required(),
            })
            setValidationSchema(newValidationSchema)
          })
          .catch(() => {
            const newValidationSchema = Yup.object().shape({
              name: Yup.string()
                .required()
                .max(100)
                .matches(
                  /^[a-zA-Z0-9]+$/,
                  'can consist of up to 100 alphanumeric characters',
                ),
              type: Yup.string().oneOf(Object.values(PlugType)).required(),
            })
            setValidationSchema(newValidationSchema)
          })
      }
      getSchema()
    } else {
      const newValidationSchema = Yup.object().shape({
        name: Yup.string()
          .required()
          .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed'),
        type: Yup.string().oneOf(Object.values(PlugType)).required(),
      })
      setValidationSchema(newValidationSchema)
    }
  }, [edgeMode])

  const plugExists = useSWRMutation(
    'verify-plug-exists-key',
    async (_, { arg }: { arg: { name: string; type: any } }) => {
      const { name, type } = arg
      return edgeMode
        ? await verifyPlugV0Exists([name, type])
        : await verifyPlugV2Exists([name])
    },
    {
      onSuccess: async nameExists => {
        if (nameExists) {
          createPlugin.setErrors({
            name: 'A plug with this name already exists',
          })
          return
        }
        const { type, name } = createPlugin.values
        edgeMode
          ? navigate(`/plugins/new?type=${type}&name=${name}`)
          : createFromExample({
              name,
              runtime: createPlugin.values.runtime.name,
              type: CustomFunctionsType.PLUGIN,
            })
              .then(res => {
                navigate(
                  `/plugins/sensors/${res?.entity?.plug?.name}?version=${res?.entity?.plug?.version}`,
                )
              })
              .catch(e => {
                const message =
                  get(e, 'response.data.message') ??
                  get(e, 'response.data.error')
                addToast(`Something went wrong: ${message}`, {
                  appearance: 'error',
                })
              })
      },
    },
  )

  function onDismiss() {
    createPlugin.setErrors({})
    hideModal()
  }

  async function onSubmit({
    name,
    type,
  }: {
    name: string
    type: PlugType
    runtime: {
      label: string
      name: string
    }
  }) {
    plugExists.trigger({ name, type })
  }
  const [showModal, hideModal] = useModal(
    () => (
      <NewModal
        title="Create new plug"
        submitText="Create plug"
        isSubmitting={plugExists.isMutating || createLoading}
        onDismiss={onDismiss}
        onSubmit={createPlugin.handleSubmit}
      >
        {createPlugin.values.type !== 'sensors' && (
          <Message kind="warning" style={{ marginBottom: '10px' }}>
            {capitalize(createPlugin.values.type)} are deprecated and will be
            removed in a future release.{' '}
          </Message>
        )}

        <Form.Field>
          <label htmlFor="name">Name</label>
          <Form.Input.Group fluid>
            <Form.Input
              fluid
              autoFocus
              name="name"
              placeholder="name"
              id="name"
              defaultValue={createPlugin.values.name}
              onChange={createPlugin.handleChange}
            />
          </Form.Input.Group>
        </Form.Field>
        {!edgeMode && (
          <Form.Field>
            <Decorator>
              <RuntimeSelection
                isModal
                setRuntime={selection => {
                  createPlugin.setFieldValue('runtime', selection)
                  createPlugin.setFieldValue('type', 'sensors')
                }}
                labelVisibility
                isNew
                runtime={createPlugin.values.runtime}
                runtimeLabel={createPlugin.values.runtime.label}
              />
            </Decorator>
          </Form.Field>
        )}
        {Object.values(createPlugin.errors)[0] && (
          <Message kind="danger">
            {Object.values(createPlugin.errors)[0]}
          </Message>
        )}
      </NewModal>
    ),
    [createPlugin],
  )

  return children({ showModal })
}

export default ModalView
