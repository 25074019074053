import React, { useEffect, useState, useMemo } from 'react'
import { Select } from '@waylay/react-components'
import { RuntimeContainer } from '../../useRuntimes'
import { isObject } from 'lodash-es'

export interface IRuntimeSelection {
  value: string
  label: string
}

interface RuntimeSelectionProps {
  labelVisibility?: boolean
  setRuntime: (value: string) => void
  isNew?: boolean
  defaultValue?: string
  runtimeLabel?: string
  readOnly?: boolean
  runtime?: {
    label: string
    name?: string
    version?: string
    title?: string
  }
  isModal?: boolean
}

const RuntimeSelection: React.FC<RuntimeSelectionProps> = ({
  labelVisibility,
  setRuntime,
  isNew,
  runtimeLabel = null,
  readOnly,
  runtime,
  isModal,
}) => {
  const {
    majorRuntimes,
    allRuntimes,
    loading,
    callbackRuntimesMap,
    nonCallbackRuntimesMap,
  } = RuntimeContainer.useContainer()

  const isPlugin = window.location.pathname.includes('plugins')
  const [isChecked, setIsChecked] = useState(false)
  const runtimesToUse = isChecked ? callbackRuntimesMap : nonCallbackRuntimesMap

  const getOptions = () => {
    if (isNew) {
      if (isPlugin && isModal) {
        return isObject(runtimesToUse) && Object?.keys(runtimesToUse).length > 0
          ? Object?.keys(runtimesToUse)?.map(runtime => {
              return {
                label: runtime,
                value: runtimesToUse[runtime].name,
              }
            })
          : []
      } else {
        return Object.keys(majorRuntimes).map(runtime => {
          return {
            label: runtime,
            value: majorRuntimes[runtime].name,
          }
        })
      }
    } else {
      const filteredAllRuntimes = []
      Object.entries(allRuntimes)?.forEach(([key, value]) => {
        if (runtime.name && (value as { name: string }).name === runtime.name) {
          filteredAllRuntimes.push({
            label: key.replace(' Plug', ''),
            value: key,
          })
        }
      })
      if (runtime) {
        const notInList =
          Object.values(allRuntimes)?.find(
            runtimeItem =>
              runtimeItem.name === runtime?.name &&
              runtimeItem.version === runtime?.version,
          ) === undefined
        if (notInList && runtime) {
          filteredAllRuntimes.push({
            label: runtime.title.replace(' Plug', ''),
            value: runtime.title,
          })
        }
      }

      return filteredAllRuntimes
    }
  }

  useEffect(() => {
    getOptions()
  }, [isChecked, getOptions])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    const runtimes = event.target.checked
      ? callbackRuntimesMap
      : nonCallbackRuntimesMap
    const selectRuntime = Object.entries(runtimes)[0]
    //@ts-expect-error
    setRuntime({ label: selectRuntime[0], name: selectRuntime[1].name })
  }

  return (
    <>
      {labelVisibility && <label htmlFor="runtime">Runtime</label>}
      {isPlugin && isModal && (
        <div
          style={{
            display: 'flex',
            paddingBottom: '10px',
          }}
        >
          <input
            style={{ height: '20px', marginRight: '5px' }}
            role="checkbox"
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label style={{ margin: '0' }} htmlFor="runtime">
            Use Callback
          </label>
        </div>
      )}

      {!loading && (
        <>
          <Select
            isDisabled={readOnly}
            name="runtime"
            placeholder="select runtime"
            id="runtime"
            options={getOptions()}
            value={{ label: runtimeLabel, value: runtime.name }}
            onChange={option => {
              //@ts-expect-error
              setRuntime({ name: option.value, label: option.label })
            }}
          />
        </>
      )}
    </>
  )
}

export default RuntimeSelection
